import { Injectable } from '@angular/core';

import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private _storage: Storage | null = null;

  constructor(private storage: Storage) {}

  async init() {
    const storage = await this.storage.create();
    this._storage = storage;
  }

  //Sovrascrive da 0 tutto il local storage nella key indicata e imposta il value come il nuovo
  public async set(value: any, key: string) {
    console.log("Ho salvato "+ key);
    console.log(value);
    await this.init();
    return await this._storage?.set(key, value);
    
  }

  //prende i dati dal local storage; se si specifica un limit, restituisce gli ultimi limit, altrimenti restituisce tutto
  public async get(key: string, limit?: number){
    await this.init();
    let data = await this._storage?.get(key);

    if (Array.isArray(data)) {
      // Ordina i dati in base al datetime in ordine discendente
      data.sort((a, b) => new Date(b.datetime).getTime() - new Date(a.datetime).getTime());

      if (limit !== undefined) {
        // Ritorna gli ultimi 'limit' elementi dell'array.
        data = data.slice(0, limit);
      }
    }
    
    return data;
  }

  public async remove(key:string){
    await this.init();
    return await this._storage?.remove(key);

  }

  
  //aggiunge un valore al local storage
  public async setNew(value: any, key: string) {
    await this.init();

    // get the current array
    const currentArray = await this._storage?.get(key) || [];

    // add the new value
    currentArray.push(value);

    // save it back
    return await this._storage?.set(key, currentArray);
  }

  public async clear(){
    await this.init();
    return await this._storage?.clear();
  }

  /**Nasconde all'utente un record presente in locale.
   * Il record è identificato dalla datetime, in changes ci sono le modifiche fatte (per delete) o il nuovo
   * array (per edit), typeOfCorrection specifica se è un delete o un edit.  */
  public async editDeleteLocalRecord(key: string, datetime: any, changes: any, typeOfCorrection : string): Promise<any> {
    await this.init();
    const currentArray = (await this._storage?.get(key)) || [];
    const index = currentArray.findIndex(item => item.datetime === datetime); // Replace 'id' with the unique property you use to identify records
    
    //Se è un delete, aggiungo le info isDeleted e le altre al vettore
    if (typeOfCorrection == 'delete') {
      if (index > -1) {
        // Update the object with new changes
        currentArray[index] = {...currentArray[index], ...changes};
        // Save it back
        await this._storage?.set(key, currentArray);
      } else {
        throw new Error(`Record with id ${datetime} not found.`);
      }
    }
    //Se è un edit, sovrascrivo le informazioni
    else if (typeOfCorrection == 'edit') {
      if (index > -1) {
        // Update the object with new changes
        currentArray[index] = changes;
        // Save it back
        await this._storage?.set(key, currentArray);
      } else {
        throw new Error(`Record with id ${datetime} not found.`);
      }
    }
  }

}
