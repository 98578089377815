/**This file contains feature in common with other environments */

export const baseEnvironment = {
    // Common settings
    
    lambdaHeader: {
        'Authorization': 'fracchiaLaBEEELVAuman@',
        'Content-Type': 'application/json',
      },

    URL: {
    foodTable: 'assets/tables/food.json',
    sportTable: 'assets/tables/sport.json',
    insulinTable: 'assets/tables/insulin.json',
    bodyratioTable: 'assets/tables/bodyratio.json',
    dummyuserTable: 'assets/tables/dummyuser.json',
    wordpressPosts: 'https://dally.it/wp-json/wp/v2/posts',
    wordpressTags: 'https://dally.it/wp-json/wp/v2/tags'
    },
      
  };
  