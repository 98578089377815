import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, Data } from '@angular/router';
import { formatISO, getDate, getDay, getHours, getMinutes, getMonth, getYear, parseISO, format } from 'date-fns';
import { StorageService } from '../database/storage/storage.service';
import { DatabaseService } from '../database/database.service';
import { EditRegisterService } from '../editRegister/edit-register.service';
import { AcceptNewPpService } from 'src/app/welcome/accept-new-pp/accept-new-pp.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(
    private storageService: StorageService,
    private router: Router,
    private databaseService: DatabaseService,
    private editRegisterService: EditRegisterService,
    private acceptNewPpService : AcceptNewPpService,
  ) { }

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    console.log(route.routeConfig.path);
    //var isWelcome =true;
    var isWelcome = route.routeConfig.path == 'welcome' || route.routeConfig.path == 'signin' || route.routeConfig.path == 'login' ? true : false;
    // IMPORT REGISTER WITH FIREBASE CONNECTION
    var NewPPJun2023 = false;
    var authenticated = await this.storageService.get('DOCID') ? true : false;
    var limit: number = 1;
    const isOver56Days = await this.checkIsOver56Days();
    console.log('isWelcome: ' + isWelcome)
    console.log('authenticated: ' + authenticated)

    // Check New Privacy Policy of June 2023 in localStorage
    NewPPJun2023 = await this.checkPPandTC();
    
    //Avvio sincronizzazione bidirezionale se necessario
    this.editRegisterService.updateLastSynchronization();

    //Controllo se l'utente è autenticato o meno
    if (!authenticated) {
      if (!isWelcome) {
        this.router.navigate(['welcome']);
        return false;
      } else {
        return true;
      }
    } 
    else {
      if (!NewPPJun2023 && route.routeConfig.path !== 'acceptNewPP') {
        this.router.navigate(['acceptNewPP']);
        return false;
      }

      if (isWelcome){
        this.router.navigate(['home']);
          return true;
      }
      
      // IF IS WELCOME && DALLY NORMALE
      if (!isWelcome) {
        if (isOver56Days){
           console.log(isOver56Days)

           this.router.navigate(['end-clinical-study']);
           return true;
        }
      }  
      
    }
  }

  /**Controlla se l'utente ha accettato l'ultima privacy policy */
  private async checkPPandTC() {

    const localAcceptance = await this.acceptNewPpService.isLegalDocumentsAccepted('local', 'NewPPJun23');
    if (localAcceptance) {
      return true;
    }
    else {
      const remoteAcceptance = await this.acceptNewPpService.isLegalDocumentsAccepted('remote', 'NewPPJun23');
      if (remoteAcceptance) {
        return true;
      }
      else {
        return false;
      }
    }

  }

  private async checkIsOver56Days(): Promise<boolean> {
    var date=new Date();
    var register = await this.storageService.get('register');
    console.log(register);
    if(register){
    var dataSignin=register.dataSignin;
    
    console.log(dataSignin);
    console.log(date);
    console.log(this.differenceBeetwenTwoDates(dataSignin, date))
    if (dataSignin==undefined){
      return false;
    }
    
    else if (this.differenceBeetwenTwoDates(dataSignin, date)>12000000000000000000000000000000000000000){
      return true; 
  }
  else {
    return false;
  }
}
else{
  return false;
}
}

  differenceBeetwenTwoDates(a, b){
    var _MS_PER_DAY =  1000*60;
    // Esclude l'ora ed il fuso orario
    var utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate(), a.getHours(), a.getMinutes());
    var utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate(), b.getHours(), b.getMinutes());
    return Math.ceil((utc2 - utc1) / _MS_PER_DAY);
  }

}
