import { Injectable } from '@angular/core';
import { StorageService } from '../storage/storage.service';
import {formatISO,} from "date-fns";
import { ToastController } from '@ionic/angular';
import { DatabaseService } from '../database.service';

@Injectable({
  providedIn: 'root'
})
export class ClinicalsettingsTableService {

  constructor(
    private storageService: StorageService,
    private toastController: ToastController,
    private databaseService: DatabaseService,
  ) { }
  
  /**Restituisce l'ultima tabella di clinicalSettings salvata in locale */
  async get() {
    return await this.storageService.get('clinicalSettings');
    // return await this.databaseService.get('clinicalSettings');
  }

  /**Salva il valore indicato in 'value' nel campo specificato da 'field';
   * se flag è True NON mostra i popup di salvataggio all'utente*/
async save(field, value, flag?:boolean) {

  //Recupero i clinical settings salvati in locale ad apertura di questa pagina e creo un vettore identico che modificherò
  const clinicalSettingsSaved = await this.get();
  var clinicalSettingsUpdated: any = { ...clinicalSettingsSaved };

  //Aggiorno datetime e il valore indicato da field e value in input alla funzione save()
  clinicalSettingsUpdated.datetime = formatISO(new Date())
  clinicalSettingsUpdated[field] = value;

  // Aggiungi questa condizione per cancellare il basalID quando si elimina l'insulina basale
  if (field === 'basalDailyUnits' && value === 0) {
    clinicalSettingsUpdated['basalID'] = '';
  }

  //Aggiorno i clinical settings locali
  //Non metto direttamente il comando qui perché voglio un await
  await this.updateLocalClinicalSettings(clinicalSettingsUpdated)
  
  //Messaggio di attesa
  if (!flag) {
    let waitingToast = await this.showWaitingMessage();
    //Salvataggio in remoto dei nuovi clinicalSettings
    await this.databaseService.set(clinicalSettingsUpdated, 'clinicalSettings').catch(err => {
      alert(err)
    })   
    //Metto messaggio di alert per dire all'utente del corretto salvataggio
    // Dismiss the waiting message toast
    await waitingToast.dismiss();
    await this.showMessage();
  }
  else {
    //Salvataggio in remoto dei nuovi clinicalSettings
    await this.databaseService.set(clinicalSettingsUpdated, 'clinicalSettings').catch(err => {
      alert(err)
    }) 
  }

}

/**Aggiorna i clinicalSettings locali */
async updateLocalClinicalSettings(clinicalSettingsUpdated) {
  const clinicalSettingsSaved =  clinicalSettingsUpdated;
  this.storageService.set(clinicalSettingsSaved, 'clinicalSettings')
  }

/**Mostra il banner "Modifica completata!" Per 3 secondi */
async showWaitingMessage() {
  const toast = await this.toastController.create({
    message: 'Attendi mentre salvo...',
    //duration: 3000, // Display for 3 seconds
    position: 'top' // You can adjust the position as needed
  });

  await toast.present();
  return toast;
}

/**Mostra il banner "Modifica completata!" Per 3 secondi */
async showMessage() {
  const toast = await this.toastController.create({
    message: 'Modifica completata! <ion-icon src="../../assets/icons/confirmGreen.svg"></ion-icon>',
    duration: 3000, // Display for 3 seconds
    position: 'top' // You can adjust the position as needed
  });
  
  toast.present();
  }


}


