import { Injectable } from '@angular/core';

import * as CryptoJS from 'crypto-js';

import { AngularFirestore } from '@angular/fire/compat/firestore';

import { StorageService } from '../database/storage/storage.service';
import { DatabaseService } from '../database/database.service';

@Injectable({
  providedIn: 'root'
})
export class IdentityManagerService {

  constructor(
    private angularFirestore: AngularFirestore,
    private storageService: StorageService,
    private databaseService: DatabaseService
  ) {
  }

  encryptID(ID, key) {
    var cipherID = CryptoJS.AES.encrypt(ID, key).toString();
    if (this.decryptID(cipherID, key) == ID) {
      return cipherID;
    }
  }

  decryptID(cipherID, key) {
    var bytes = CryptoJS.AES.decrypt(cipherID, key);
    var originalText = bytes.toString(CryptoJS.enc.Utf8);
    return originalText
  }

  /**Consento il sign-in dell'utente, salvando in locale e in remoto userID, password e dati clinici ('data') */
  async signin(userID, mnemonickey, data) {
    
    //Salvo in locale userID e password
    await this.storageService.set(userID, 'userID');
    await this.storageService.set(mnemonickey, 'mnemonickey');

    //Genero DOCID
    var DOCID = await this.generateDOCID(userID);

    //Salvo DOCID e userID su Firebase in userList
    console.log('DOCID: ' + DOCID)
    var userList = await this.angularFirestore.collection('userList').doc(DOCID);
    await userList.set({ 'userID': userID });
    console.log('Nuovo userList settato');

    //Salvo DOCID e userID su Firebase in userLogin
    const sanitizedUserID = this.sanitizeUserID(userID);
    var userLogin = await this.angularFirestore.collection('userLogin').doc(sanitizedUserID);
    await userLogin.set({ 'DOCID': DOCID });
    console.log('Nuovo userLogin settato'); 

    //Salvo i clinicalsettings to new doc
    var clinicalsettings = await this.angularFirestore.collection('userData').doc('clinicalSettings').collection(DOCID).doc(data.datetime);
    await clinicalsettings.set(data);
    console.log('Ho salvato i seguenti clinicalSettings')
    console.log(clinicalsettings)
    
    //Salvo il DOCID in locale
    await this.storageService.set(DOCID, 'DOCID');

    return true;
  }

  /**Genera un DOCID casuale usando l'userID dell'utente e i secondi dell'ora attuale */
  async generateDOCID(userID: string): Promise<string> {
    const date = new Date();
    const currentMinute = date.getSeconds().toString();

    // Concatenating userID and current minute
    const seed = userID + currentMinute;

    // Create a hash of the seed
    let hash = CryptoJS.SHA256(seed).toString(CryptoJS.enc.Hex).substring(0, 16);

    // Randomly add four alphanumeric characters to make it 20 characters long
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < 4; i++) {
      hash += characters.charAt(Math.floor(Math.random() * characters.length));
    }

    return hash;
  }

  /**Sostituisce i caratteri non alfanumerici con '0'  */
  sanitizeUserID(userID: string): string {
    return userID.replace(/[^a-zA-Z0-9]/g, '0');
  }

  /**Consente il login dentro l'app, prende in input userID, password e codice fiscale. */
  async login(cipherID, key, fiscalcode) {

    //Decritto codice fiscale da userID e password
    var decriptedID = this.decryptID(cipherID, key);
    console.log(decriptedID)

    //Se il codice fiscale decrittato è identico a quello usato dall'utente, autorizzo il login, altrimenti no
    if (fiscalcode == decriptedID){

      //Salvo in locale userID e password
      await this.storageService.set(cipherID, 'userID');
      await this.storageService.set(key, 'mnemonickey');
      
      //Login su Firestore
      var DOCID = (await this.recoverDOCID(cipherID));
      console.log(DOCID)
      if (DOCID != undefined) {
        //Salvo in locale il DOCID
        await this.storageService.set(DOCID, 'DOCID');
        //Recupero tutti i vecchi dati
        await this.databaseService.sync('all', 1000);  

        return true
      } else {
        return false
      }
    }  else {
      return false
    }
  }

  /**Recupero il DOCID noto lo userID */
  async recoverDOCID(userID) {
    //Recupero il DOCID noto lo userID su Firestore, leggendo dentro userLogin
    const sanitizedUserID = this.sanitizeUserID(userID);
    console.log(sanitizedUserID)

    let DOCID = null;

    try {
      const docRef = this.angularFirestore.collection('userLogin').doc(sanitizedUserID);
      const docSnapshot = await docRef.get().toPromise();

      if (docSnapshot.exists) {
        console.log('Document exists:', docSnapshot.data());
        const data = docSnapshot.data() as { DOCID: string };
        DOCID = data.DOCID;

      } else {
        console.log('Document does not exist.');
      }
    } catch (error) {
      console.error('Error fetching document:', error);
    }
    console.log('DOCID: ')
    console.log(DOCID)

    return DOCID;
  }

  async logout(){
    await this.storageService.clear();
  }
}
