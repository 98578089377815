import { Component } from '@angular/core';

import { Keyboard } from '@capacitor/keyboard'; 


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor()
    {}

}
