import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import { StorageService } from '../database/storage/storage.service';

import { formatISO } from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class DatabaseService {

  constructor(
    private angularFirestore: AngularFirestore,
    private storageService: StorageService,
  ) { }

  dbArray = [
    { name: 'clinicalSettings', limit: 1 },
    { name: 'correctionPayload', limit: 50 },
    { name: 'correctionResult', limit: 50 },
    { name: 'foodFavorite', limit: 10 },
    { name: 'sportFavorite', limit: 10 },
    { name: 'glycatedHemoglobin', limit: 50 }
  ]

  /**Fissa una nuova voce nel db remoto. 'data' è il valore, 'key è la stringa nome*/
  async set(data, key) {
    try {
      var DOCID = await this.storageService.get('DOCID');
      var document = await this.angularFirestore.collection('userData').doc(key).collection(DOCID).doc(data.datetime);
      await document.set(data);
    } catch (error) {
      console.log(error)
      this.raiseError(key,'01',error)
    }
  } 
  
  /**Modifica una voce nel DB remoto. 'data' è il valore, 'key è la stringa nome, 'datetime' è la data del record. */
  async editRecord(data, key, datetime) {
    try {
      var DOCID = await this.storageService.get('DOCID');
      var document = await this.angularFirestore.collection('userData').doc(key).collection(DOCID).doc(datetime);
      await document.set(data);
    } catch (error) {
      console.log(error)
      this.raiseError(key,'01',error)
    }
  }  

  /**Prende l'ultima voce registrata di un certo documento; se specifico limit, allora prendo limit dati (ad esempio, se limit=3, le ultime 3 voci) */
  async get(key, limit = 1) {
    try {
      if (!key || key.trim() === '') {
        console.warn("Key is empty. Exiting get() method.");
        return null;
      }
    
      var DOCID = await this.storageService.get('DOCID');
    
      if (!DOCID || DOCID.trim() === '') {
        console.warn("DOCID is empty. Exiting get() method.");
        return null;
      }
    
      var res = [];
      var query = await this.angularFirestore.collection('userData').doc(key).collection(DOCID, ref => ref.orderBy('datetime', 'desc').limit(limit)).get().toPromise();
      query.forEach(doc => {
        res.push(doc.data());
      });
      return limit == 1 ? res[0] : res;
    } catch (error) {

      this.raiseError(key,'02',error, limit)

    }
  }
  
  /**Sincronizzazione bidirezionale di una collection indicata dalla key */
  async bySynchCollection(key: string, limit=100) {
    try {
      if (!key || key.trim() === '') {
        console.warn("Key is empty. Exiting synchronizeCollection() method.");
        return;
      }
      else {
        console.log('Bisincronizzo ' + key)
      }
      
      //Prendo il DOCID dal local service
      var DOCID = await this.storageService.get('DOCID');
  
      if (!DOCID || DOCID.trim() === '') {
        console.warn("DOCID is empty. Exiting synchronizeCollection() method.");
        return;
      }
  
      // Step 1: Download records from Firebase
      //console.log('Limite: '+ limit)
      const remoteData = await this.get(key, limit);
      //console.log('Dati remoti:')
      //console.log(remoteData)
  
      // Step 2: Get records from local storage
      const localData = await this.storageService.get(key, limit);
      //console.log('Dati locali:')
      //console.log(localData)
      
      //Trattiamo i clinicalSettings a parte perché in quel caso ci interessa sincronizzare solo l'ultimo elemento
      if (key == 'clinicalSettings') {

        const localDatetime = new Date(localData.datetime);
        //console.log('localDatetime ' + localDatetime)      
        const remoteDatetime = new Date(remoteData.datetime);
        //console.log('remoteDatetime ' + remoteDatetime)

        console.log(localData);
        console.log(remoteData);

        // Compare the datetime attributes and add the most recent one to the result
        if (localDatetime > remoteDatetime) {
          //Salvo in remoto i dati di locale, che sono più recenti
          this.set(localData, key);
        } else if (localDatetime < remoteDatetime) {
          //Salvo in locale i dati di remoto, che sono più recenti
          await this.storageService.set(remoteData, key);    
      }
      //Sincronizziamo i vari altri dati, che sono array perché contengono più dati
      } else {

        // Step 3: Identify new records in local that there are not in the remote database
        const newRecords = await this.findNewRecords(localData, remoteData);
    
        // Step 4: Write only the new records to the remote database
        if (newRecords) {
          console.log('Trovati record locali non salvati in remoto')
          await this.writeToRemoteDatabase(newRecords, key);
        }  

        //Step 5: Synch deleted data
        const remoteDataUpdated = await this.get(key, limit);
        const localDataUpdated = await this.storageService.get(key, limit);
        if (remoteDataUpdated && localDataUpdated) {        
          for (const localData of localDataUpdated) {
            
            //Caso in cui trovo un record che è stato poi eliminato
            if (localData.isDeleted) {

              //Cerco nel DB remoto se il record corrispondente ha pure il tag di eliminazione
              for (const remoteData of remoteDataUpdated) {
                if (remoteData.datetime == localData.datetime) {

                  //Se anche quello ha il tag di eliminazione, allora non faccio niente; altrimenti, devo andare a salvare l'eliminazione
                  if (!remoteData.isDeleted) {
                    this.editRecord(localData, key, localData.datetime);
                  }
                }
              }
            }

          }
      }

      }
      
    } catch (error) {
      console.log('Errore di bi-sincronizzazione in ' + key)
      this.raiseError(key, '02', error);      
    }
  }
  
  /**Trova nuovi record non salvati o in locale, o in remoto */
  async findNewRecords(localData, remoteData) {
  if (Array.isArray(remoteData) && Array.isArray(localData)) {
    // Create a map of datetime values from remoteData
    const remoteDatetimeMap = new Map(remoteData.map(record => [record.datetime, true]));
    
    // Filter out records that are not already in the remoteData
    return localData.filter(localRecord => {
      return !remoteDatetimeMap.has(localRecord.datetime);
    });
  } 
  else {
    // Handle the case when localData and remoteData are single elements
    if (localData && remoteData) {
      const localDatetime = new Date(localData.datetime);
      //console.log('localDatetime ' + localDatetime)      
      const remoteDatetime = new Date(remoteData.datetime);
      //console.log('remoteDatetime ' + remoteDatetime)

      // Compare the datetime attributes and add the most recent one to the result
      if (localDatetime > remoteDatetime) {
        return localData;
      } else if (localDatetime < remoteDatetime) {
        return remoteData;
      }
      else {
        return null;
      }
    }
  }
}

/**Scrivere i record di una collection 'data' nel DB remoto */
  async writeToRemoteDatabase(data, key) {
    for (const record of data) {
      await this.set(record, key);
      //console.log('Ho salvato questo nel DB emoto')
      //console.log(key)
      //console.log(record)
    }
  }

  /**Rimuove i record duplicati */
  removeDuplicateRecords(localData, remoteData) {
    // Create a map of datetime values to identify duplicates
    const datetimeMap = new Map();

    // Filter out records that are already in remoteData
    const uniqueLocalData = localData.filter((localRecord) => {
      const datetime = localRecord.datetime;
      if (!datetimeMap.has(datetime)) {
        datetimeMap.set(datetime, true);
        return !remoteData.some((remoteRecord) => remoteRecord.datetime === datetime);
      }
      return false;
    });

    // Combine unique local data with remote data
    return uniqueLocalData.concat(remoteData);
  }  

  /**Sincronizzazione MONOdirezionale: prendo dal DB Firebase i dati (che considero come veri) e SOVRASCRIVO quelli in locale con quelli in remoto. */
  async sync(key = 'all', limit = 1) {
    try {
      console.log('Scarico dati top-down');
      if (key == 'all') {
        for (const element of this.dbArray) {
          var res = await this.get(element.name, element.limit);
          if (res) {
            this.storageService.set(res, element.name);
          }
        }
      } else {
        var res = await this.get(key, limit);
        if (res) {
          this.storageService.set(res, key);
        }
      }
    } catch (error) {
      
      this.raiseError(key,'03',error, limit)

    }
  }
 
  /**Evidenzia errore */
  raiseError(key: string, type: string, error: any, limit?: number) {    
    let keyDict = [
      { name: 'clinicalSettings', code: '01'},
      { name: 'correctionPayload', code: '02'},
      { name: 'correctionResult', code: '03'},
      { name: 'feedbackEdu', code: '04'},
      { name: 'foodFavorite',  code: '05'},
      { name: 'sportFavorite',  code: '06'},
      { name: 'glycatedHemoglobin', code: '07'},
      { name: 'newLegal', code: '08'},
      { name: 'tipsFeedback', code: '09'}
    ]
  
    let errorCode = [
      { num: '01'}, // Writing a new record
      { num: '02'}, // Reading a record
      { num: '03'}, // Top-down synchronization
    ]
  
    for (const element of keyDict) {
      for (const element2 of errorCode) {
        if (key == element.name && type == element2.num) {
          const errorMessage = `Error while processing key: ${key}, type: ${type}, limit: ${limit || 'N/A'}.\nError Details: ${error.message || 'No error message available.'}`;
          console.error(errorMessage);
  
          alert("Errore di connessione con il server di Dally. Fai screen a questo messaggio e contatta il team tecnico di Dally. Codice errore: ERR" + element.code + "X" + type);
        }
      }
    }
  }
  
  

 }

