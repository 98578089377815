import { Injectable } from '@angular/core';
import { StorageService } from 'src/app/service/database/storage/storage.service';
import { DatabaseService } from 'src/app/service/database/database.service';
import formatISO from 'date-fns/formatISO';

@Injectable({
  providedIn: 'root'
})
export class AcceptNewPpService {

  constructor(
    private storageService : StorageService,
    private databaseService : DatabaseService,
  ) { }
  
  /**Salvo sia in locale che in remoto che ho accettato i documenti legali aggiornati
   * al periodo indicato da idDocuments, ad esempio 'NewPPJun2023' */
  async acceptNewPPandTnC(idDocuments : string) {  
    const data = {
      idDocuments : idDocuments,
      flag: true,
      datetime: formatISO(new Date())
    }
    await this.storageService.set(data, idDocuments);
    await this.databaseService.set(data, 'newLegal');
  }

  /**Restituisce true se in un certo ambiente ho trovato salvato che i documenti legali sono
   * stati accettati; 'ambient' è 'local' o 'remote', idDocuments gli ID dei documenti da checkare. */
  async isLegalDocumentsAccepted(ambient : string, idDocuments) {
    
    if (ambient == 'local') {
      
      const localData = await this.storageService.get(idDocuments);
      
      if (localData) {
        if (localData.flag) {
          return true;
        }
        else {
          return false;
        }
      }

      else {
        return false;
      }      
    }
    else if (ambient == 'remote') {
      const remoteData = await this.databaseService.get('newLegal');
      if (remoteData) {
        if (remoteData.idDocuments == idDocuments) {
          if (remoteData.flag == true) {
            return true;
          }
          else {
            return false;
          }
        }
      }
      else {
        return false;
      }
    }
  }

}
