import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AuthGuardService} from './service/identityManager/auth-guard.service';
import {AcceptNewPPComponent} from './welcome/accept-new-pp/accept-new-pp.component';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'homelite',
    loadChildren: () => import('./home-lite/homelite.module').then(m => m.HomelitePageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'acceptNewPP', component: AcceptNewPPComponent
  },
  {
    path: 'addRecord',
    loadChildren: () => import('./addRecord/add-record.module').then(m => m.InputPageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'signin',
    loadChildren: () => import('./welcome/signin/signin.module').then(m => m.SigninPageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'login',
    loadChildren: () => import('./welcome/login/login.module').then(m => m.LoginPageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'welcome',
    loadChildren: () => import('./welcome/welcome.module').then(m => m.WelcomePageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfilePageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'diary',
    loadChildren: () => import('./diary/diary.module').then(m => m.DiaryPageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'report',
    loadChildren: () => import('./report/report.module').then(m => m.ReportPageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'blog',
    loadChildren: () => import('./blog/blog.module').then(m => m.BlogPageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'tips/:selectedItems',
    loadChildren: () => import('./tips/tips.module').then(m => m.TipsPageModule),
    canActivate: [AuthGuardService],
  },
  /*{
    path: 'antiplatelets',
    loadChildren: () => import('./profile/anamnesis/drugs/antiplatelets/antiplatelets.module').then( m => m.AntiplateletsPageModule)
  },*/
  {
    path: 'drugs',
    loadChildren: () => import('./profile/anamnesis/drugs/drugs.module').then(m => m.DrugsPageModule)
  },
  {
    path: 'quiz',
    loadChildren: () => import('./quiz/quiz.module').then(m => m.QuizPageModule)
  },
  {
    path: 'edu-quiz',
    loadChildren: () => import('./blog/edu-quiz/edu-quiz.module').then(m => m.EduQuizPageModule)
  },
  {
    path: 'edu-tip',
    loadChildren: () => import('./blog/edu-tip/edu-tip.module').then(m => m.EduTipPageModule)
  },
  {
    path: 'add-record-lite',
    loadChildren: () => import('./add-record-lite/add-record-lite.module').then(m => m.AddRecordLitePageModule)
  },
  {
    path: 'diary-lite',
    loadChildren: () => import('./diary-lite/diary-lite.module').then(m => m.DiaryLitePageModule)
  },
  {
    path: 'component',
    loadChildren: () => import('./component/component.module').then(m => m.ComponentPageModule)
  },
  {
    path: 'vas-qol',
    loadChildren: () => import('./vas-qol/vas-qol.module').then(m => m.VasQolPageModule)
  },
  {
    path: 'end-clinical-study',
    loadChildren: () => import('./end-clinical-study/end-clinical-study.module').then(m => m.EndClinicalStudyPageModule)
  },
  {
    path: 'screening-survey',
    loadChildren: () => import('./screening-survey/screening-survey.module').then( m => m.ScreeningSurveyPageModule)
  },
  {
    path: 'screening-signup',
    loadChildren: () => import('./screening-signup/screening-signup.module').then( m => m.ScreeningSignupPageModule)
  },
  {
    path: 'screening-summary',
    loadChildren: () => import('./screening-summary/screening-summary.module').then( m => m.ScreeningSummaryPageModule)
  },
  {
    path: 'screening-welcome',
    loadChildren: () => import('./screening-welcome/screening-welcome.module').then( m => m.ScreeningWelcomePageModule)
  },
  {
    path: 'edu-glossary',
    loadChildren: () => import('./blog/edu-glossary/edu-glossary.module').then( m => m.EduGlossaryPageModule)
  },
  {
    path: 'edu-all-exercises',
    loadChildren: () => import('./blog/edu-all-exercises/edu-all-exercises.module').then( m => m.EduAllExercisesPageModule)
  },
  {
    path: 'edu-exercise',
    loadChildren: () => import('./blog/edu-exercise/edu-exercise.module').then( m => m.EduExercisePageModule)
  },
  {
    path: 'edu-faq',
    loadChildren: () => import('./blog/edu-faq/edu-faq.module').then( m => m.EduFaqPageModule)
  },
  {
    path: 'edu-all-tips',
    loadChildren: () => import('./blog/edu-all-tips/edu-all-tips.module').then( m => m.EduAllTipsPageModule)
  },








];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
