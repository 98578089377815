// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts` foe example.
//This is the defualt environment, that points to Dally Development.
// The list of file replacements can be found in `angular.json`.

//Import base environment
import { baseEnvironment } from "./environment.base";

export const environment = {

  //Import common configuration from baseEnvironment
  ...baseEnvironment,

  production: false,

    firebase : {
      apiKey: "AIzaSyCe7DPmyOepUwjCP5Lbp4hGxnTNQIYdT-A",
      authDomain: "dally-clinicalstudies.firebaseapp.com",
      projectId: "dally-clinicalstudies",
      storageBucket: "dally-clinicalstudies.appspot.com",
      messagingSenderId: "739980395611",
      appId: "1:739980395611:web:e51471c32088908aaf6b7e"
  },

  flagDevelopersPage : false,

  flagPostHog: true,

  flagConsoleLog: false,

  flagTimingFeatures: true,
  
  appName: 'Dally DTx',
  
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
