import { Component } from '@angular/core';
import { StorageService } from 'src/app/service/database/storage/storage.service';
import { Router } from '@angular/router';
import { DatabaseService } from 'src/app/service/database/database.service';
import { formatISO, getDate, getDay, getHours, getMinutes, getMonth, getYear, parseISO } from 'date-fns';
import { AlertController } from '@ionic/angular';
import { NavController } from '@ionic/angular';
import { IdentityManagerService } from 'src/app/service/identityManager/identity-manager.service';
import { AcceptNewPpService } from './accept-new-pp.service';

@Component({
  selector: 'app-accept-new-pp',
  templateUrl: './accept-new-pp.component.html',
  styleUrls: ['./accept-new-pp.component.scss']
})
export class AcceptNewPPComponent {
  constructor(
    private storageService: StorageService,
    private router: Router,
    private databaseService: DatabaseService,
    private alertController: AlertController,
    private navController: NavController,
    private identityManagerService: IdentityManagerService,
    private acceptNewPPService : AcceptNewPpService) {}

    legalPrivacy: boolean = false;
    legalDataUsage: boolean = false; 
    
  async accept(){
    await this.acceptNewPPService.acceptNewPPandTnC('NewPPJun23');
    this.router.navigate(['home']);
  }
  
  isAcceptButtonDisabled() {
    return !(this.legalDataUsage && this.legalPrivacy);
  }

  back() {
    this.closePage()
  }

  async closePage() {
    const alert = await this.alertController.create({
      header: 'Vuoi chiudere la pagina?',
      message: '',
      mode: 'ios',
      cssClass: 'customAlert',
      buttons: [
        {
          text: 'Annulla',
          role: 'cancel',
          handler: () => {
          },
        },
        {
          text: 'Chiudi pagina',
          role: 'confirm',
          cssClass: 'alertColor',
          handler: async () => {
            await this.identityManagerService.logout();
            this.navController.navigateBack('welcome');
          },
        },
      ],
    });

    await alert.present();
  }
}
