import { Injectable } from '@angular/core';
import { StorageService } from '../database/storage/storage.service';
import { DatabaseService } from '../database/database.service';
import { ClinicalsettingsTableService } from '../database/tables/clinicalsettings-table.service';

@Injectable({
  providedIn: 'root'
})
export class EditRegisterService {

  constructor(
    private storageService : StorageService,
    private databaseService : DatabaseService,
    private clinicalsettingsTableService : ClinicalsettingsTableService,
  ) { }
  
  /**Se true,  import VASQoLVisualization come false e quizVisualization come false; se false, metto quizVisualization come false
   * e non cambio altro praticamente
  */
  async modifyRegisterVASQoL(flag: boolean, register) {
    if(flag){
      register = 
      {
        datetime: new Date(),        
        cohort: register.cohort,
        dataSignin: register.dataSignin,
        numberofswitch: register.numberofswitch,
        onboardingHome: register.onboardingHome,
        onboardingDiary: register.onboardingDiary,
        onboardingProfile: register.onboardingProfile,
        onboardingReport: register.onboardingReport,
        onboardingBlog: register.onboardingBlog,
        onboardingGlycemia: register.onboardingGlycemia,
        onboardingMeal: register.onboardingMeal,
        onboardingActivity:  register.onboardingActivity,
        quizVisualization: false,
        dateLastQuiz: register.dateLastQuiz,
        dateLastVASQol: new Date(),
        VASQoLVisualization: false,
        //dateLastQuiz: new Date()
      };
      this.storageService.set(register, "register");
      return register
    }
    else{
      register = 
      {
        datetime: new Date(),
        cohort: register.cohort,
        dataSignin: register.dataSignin,
        numberofswitch: register.numberofswitch,
        onboardingHome: register.onboardingHome,
        onboardingDiary: register.onboardingDiary,
        onboardingProfile: register.onboardingProfile,
        onboardingReport: register.onboardingReport,
        onboardingBlog: register.onboardingBlog,
        onboardingGlycemia: register.onboardingGlycemia,
        onboardingMeal: register.onboardingMeal,
        onboardingActivity:  register.onboardingActivity,
        quizVisualization: false,
        dateLastQuiz: register.dateLastQuiz,
        dateLastVASQol: register.dateLastVASQol,
        VASQoLVisualization: register.VASQoLVisualization,
        //dateLastQuiz: new Date()
      };
      this.storageService.set(register, "register");
      return register
    }
   
   
  }

  /**Modifica la coorte dell'utente e aggiorna opportunamente il register */
  async modifyCohort(register){
    if(register.cohort=="Dally"){
    register = 
      {
        datetime: new Date(),
        cohort: "Dally_Lite",
        dataSignin: register.dataSignin,
        //dataSignin: new Date(),
        numberofswitch: register.numberofswitch + 1,
        onboardingHome: register.onboardingHome,
        onboardingDiary: register.onboardingDiary,
        onboardingProfile: register.onboardingProfile,
        onboardingReport: register.onboardingReport,
        onboardingBlog: register.onboardingBlog,
        onboardingGlycemia: register.onboardingGlycemia,
        onboardingMeal: register.onboardingMeal,
        onboardingActivity:  register.onboardingActivity,
        quizVisualization: false,
        dateLastQuiz: register.dateLastQuiz,
        dateLastVASQol: register.dateLastVASQol,
        VASQoLVisualization: register.VASQoLVisualization,
        //dateLastQuiz: new Date()
      };
      await this.storageService.set(register, "register");
      return register
    }
    else{
      register = 
      {
        datetime: new Date(),
        cohort: "Dally",
        dataSignin: register.dataSignin,
        //dataSignin: new Date(),
        numberofswitch: register.numberofswitch + 1,
        onboardingHome: register.onboardingHome,
        onboardingDiary: register.onboardingDiary,
        onboardingProfile: register.onboardingProfile,
        onboardingReport: register.onboardingReport,
        onboardingBlog: register.onboardingBlog,
        onboardingGlycemia: register.onboardingGlycemia,
        onboardingMeal: register.onboardingMeal,
        onboardingActivity:  register.onboardingActivity,
        quizVisualization: false,
        dateLastQuiz: register.dateLastQuiz,
        dateLastVASQol: register.dateLastVASQol,
        VASQoLVisualization: register.VASQoLVisualization,
        //dateLastQuiz: new Date()
      };
      await this.storageService.set(register, "register");
      return register
    }
      
  }

  /**Se true, metto quizVisualization come true e aggiorno la data del register. Se false, aggiorno la vecchia data del register con quella odierna e quizVisualization è false*/
  async modifyAccessRegister(flag: boolean, register) {
    if(flag){
      register = 
      {
        datetime: new Date(),        
        cohort: register.cohort,
        dataSignin: register.dataSignin,
        numberofswitch: register.numberofswitch,
        onboardingHome: register.onboardingHome,
        onboardingDiary: register.onboardingDiary,
        onboardingProfile: register.onboardingProfile,
        onboardingReport: register.onboardingReport,
        onboardingBlog: register.onboardingBlog,
        onboardingGlycemia: register.onboardingGlycemia,
        onboardingMeal: register.onboardingMeal,
        onboardingActivity:  register.onboardingActivity,
        quizVisualization: true,
        dateLastQuiz: new Date(),
        dateLastVASQol: register.dateLastVASQol,
        VASQoLVisualization: register.VASQoLVisualization,
        //dateLastQuiz: new Date()
      };
      await this.storageService.set(register, "register");
      return register;
    }
    else{
      register = 
      {
        datetime: new Date(),
        cohort: register.cohort,
        dataSignin: register.dataSignin,
        numberofswitch: register.numberofswitch,
        onboardingHome: register.onboardingHome,
        onboardingDiary: register.onboardingDiary,
        onboardingProfile: register.onboardingProfile,
        onboardingReport: register.onboardingReport,
        onboardingBlog: register.onboardingBlog,
        onboardingGlycemia: register.onboardingGlycemia,
        onboardingMeal: register.onboardingMeal,
        onboardingActivity:  register.onboardingActivity,
        quizVisualization: false,
        dateLastQuiz: register.dateLastQuiz,
        dateLastVASQol: register.dateLastVASQol,
        VASQoLVisualization: register.VASQoLVisualization,
        //dateLastQuiz: new Date()
      };
      await this.storageService.set(register, "register");
      return register;
    }
   
   
  }

  /**Aggiorna il register con la data della sincronizzazione bidirezionale fatta */
async updateLastSynchronization() {
  let clinicalSettings = await this.clinicalsettingsTableService.get();
  let DOCID = await this.storageService.get('DOCID');
  const currentTime = new Date();

  /**Controllo se il DOCID è definito; se infatti non è definito, vuol dire che non sono loggato,
   * quindi non ha senso lanciare il resto della funzione
   */
  if (DOCID) {

    if (!clinicalSettings.lastSynchronization) {      
      // If lastSynchronization doesn't exist, set it to January 1, 2020
      this.clinicalsettingsTableService.save('lastSynchronization', new Date('2020-01-01'), true);
      clinicalSettings.lastSynchronization = new Date('2020-01-01');
    }
    const lastSynchronizationDate = new Date(clinicalSettings.lastSynchronization);

    // Calculate the time difference in milliseconds
    const timeDifference = currentTime.getTime() - lastSynchronizationDate.getTime();

    // Check if the last synchronization was more than 24 hours ago (24 hours * 60 minutes * 60 seconds * 1000 milliseconds)
    if (timeDifference > 24 * 60 * 60 * 1000) {
      
      if (navigator.onLine) {
        console.log('Bi-sincronizzazione in corso');
        let dbArray = [
          { name: 'clinicalSettings', limit: 1 },
          { name: 'correctionPayload', limit: 50 },
          { name: 'correctionResult', limit: 50 },
          { name: 'foodFavorite', limit: 10 },
          { name: 'sportFavorite', limit: 10 },
        ];

        for (const item of dbArray) {
          await this.databaseService.bySynchCollection(item.name, item.limit);
        }

        await this.clinicalsettingsTableService.save('lastSynchronization', currentTime, true);

      }
    }

    }

}

}
